import { TerminalIcon, UserIcon } from 'components/common/icons'
import { SectionContent, StyledSection } from './components'

export default function AboutMe() {
  return (
    <StyledSection id='about'>
      <div className="section-wrapper">

        <div className="section-heading">
          <h5 className="caption">ABOUT ME & MY WORK</h5>
          <h2 className="title">Think, Make, Solve</h2>
        </div>

        <SectionContent className="section-content">

          <article title="professional">
            <TerminalIcon className='content-icon' />
            <p className="content-description">
              My mission as a Software Engineer is to deliver elegant and efficient solutions that translate Good Design into Good Business and even better user experiences.
            </p>
          </article>

          <article title="personal">
            <UserIcon className='content-icon' />
            <p className="content-description">
              When i'm not at my desk, i'll be out on the occasional road-trip or playing golf, recently i've started working on small wood-working projects, and whenever i can, i enjoy hiking & camping.
            </p>
          </article>

        </SectionContent>

      </div>
    </StyledSection>
  )
}
