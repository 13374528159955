import { Project } from "utils/types";

export const parseImageAsset = (image: any) => image?.asset?.gatsbyImageData;

export const parseBlockContent = (block = []) => block?.[0]?.children?.[0].text as string ?? '';

export const parseSanityProjects = (data: object[]) => {
  const projects = data.map((node: any) => ({
    ...node,
    slug: node.slug.current,
    coverImage: parseImageAsset(node.coverImage),
    description: parseBlockContent(node.description),
    skills: node?.skills?.map((a: any) => ({ ...a, image: parseImageAsset(a.image) })) ?? [],
    images: node.images.map((image: any) => parseImageAsset(image)),
  }));
  return projects as Project[];
}

export const parseSanitySkills = (data = []) => {
  return data.map((node) => ({
    ...node,
    image: parseImageAsset(node.image),
  }))
}
