import { graphql, StaticQuery } from "gatsby";
import { parseSanityProjects } from "utils/graphql/adapters";
import { ProjectSection } from "./component";

const Projects: React.FC = () => {
  return (
    <StaticQuery
      query={graphql`
        query allSanityProjects {
          projects: allSanityProject {
            nodes {
              ...SanityProjectInfo
            }
          }
        }
      `}
      render={(data) => <ProjectSection data={parseSanityProjects(data?.projects?.nodes)} />}
    />
  )
};

export default Projects;
