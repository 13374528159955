import { Grid, Link, ProjectCard } from 'components';
import React, { useMemo } from 'react';
import { Project } from 'utils/types';
import { SectionContent, SectionHeading, StyledSection } from './components';

type ProjectProps = {
  data: Project[]
}

export const ProjectSection: React.FC<ProjectProps> = ({ data }) => {

  const projects = useMemo(() => data.filter(p => !!p.featured), [data]);

  return (
    <StyledSection id="projects">
      <div className="section-wrapper">

        <SectionHeading className="section-heading">
          <div className="flex">
            <h5 className="caption">FEATURED PROJECTS</h5>
            <h2 className="title">My Recent Projects</h2>
          </div>
          <Link
            to='/projects/'
            title='View All Projects'
            className='btn link-btn'
          />
        </SectionHeading>

        <SectionContent className="section-content">
          {
            projects.map((project) => <ProjectCard key={project.id} data={project} />)
          }
        </SectionContent>

        <div className="btn-wrapper">
          <Link
            to='/projects/'
            title='View All Projects'
            className='btn link-btn'
          />
        </div>

      </div>
      <Grid />
    </StyledSection>
  )
}
