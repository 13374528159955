import styled from 'styled-components';

export const StyledSection = styled.section`
  display: grid;
  min-height: 90vh;
  position: relative;
  align-items: center;
  padding: 2.5rem 0.5rem;
  justify-content: center;
  grid-template-columns: 0.16fr auto 0.16fr;

  &::after, &::before {
    content: '';
    display: block;
    min-height: 1px;
  }

  .section-wrapper {
    display: grid;
    row-gap: 2.5rem;
    column-gap: 4rem;
    grid-auto-columns: 1fr;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    max-width: 59rem !important;
  }

  @media only screen and (max-width: 1024px) {
    padding: 5rem;
    min-height: 80vh;
    grid-template-columns: 1fr;

    &::after, &::before, .column-lines {
      display: none;
    }

    .section-wrapper {
      gap: 5rem;
      width: 100%;
      max-width: 30rem !important;
      grid-template-rows: auto auto;
      grid-template-columns: 1fr;
    }
  }

  @media only screen and (max-width: 720px) {
    padding: 3rem 2rem;
  }
`;

export const StyledMenu = styled.div`
  gap: 2rem;
  grid-row: 1;
  display: flex;
  grid-column: 1;
  height: 2.5rem;
  align-items: baseline;

  .app-logo {
    font-weight: 800;
    background-color: var(--yellow);
    margin-top: -2rem;
  }

  @media only screen and (max-width: 1024px) {
    height: auto;
    .app-logo {
      top: 1.5rem;
      left: 2.5rem;
      width: 3.25rem;
      height: 2.45rem;
      margin-top: 0rem;
      font-size: 1.1rem;
      position: absolute;
    }
  }

  @media only screen and (max-width: 720px) {
    .app-logo {
      top: 1.25rem;
      left: 1.25rem;
      width: 3rem;
    }
  }
`;

export const StyledCoverImage = styled.div`
  height: 26rem;
  grid-row: 1/-1;
  padding-left: 2rem;

  .hero-image {
    display: flex;
    position: relative;
    height: 25rem !important;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

export const SectionContent = styled.div`
  position: relative;
  max-height: 18.5rem;

  .caption {
    margin: 0;
    font-size: .8rem;
    color: var(--blue);
    text-transform: uppercase;
  }

  .name {
    font-weight: 900;
    font-size: 2.5rem;
    margin-top: 1.25rem;
    font-family: var(--bold);
  }

  .about-me {
    max-width: 24rem;
    font-size: .95rem;
    line-height: 1.8;
    em, b {
      font-style: normal;
      font-weight: 600;
    }
  }

  .download-btn {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 0.7rem !important;
    padding: 1.1rem 2rem !important;
    border-radius: 0.2px !important;
    letter-spacing: .03rem !important;
  }

  @media only screen and (max-width: 1024px) {
    .caption {
      font-size: .9rem;
    }
    .name {
      font-size: 2.2rem;
      letter-spacing: 0.02rem;
    }
    .about-me {
      max-width: none;
      font-size: 1rem;
      line-height: 2.2;
    }
    .download-btn {
      margin-top: 1.5rem;
      font-size: 0.85rem !important;
      padding: 1.5rem 2rem !important;
    }
  }
`;

export const SkeletonLoader = styled.div`
  --skeleton-bg: #E7E7EF;
  top: 0;
  left: 0;
  width: 24rem;
  height: 11.5rem;
  position: absolute;
  margin-bottom: 1.5rem;
  background: transparent;
  background-repeat: no-repeat;
  background-image:
    linear-gradient(var(--skeleton-bg) 100%, transparent 0),
    linear-gradient(var(--skeleton-bg) 100%, transparent 0),
    linear-gradient(var(--skeleton-bg) 100%, transparent 0),
    linear-gradient(var(--skeleton-bg) 100%, transparent 0),
    linear-gradient(var(--skeleton-bg) 100%, transparent 0);
  background-size:
    30% 0.80rem, /* caption */
    70% 2.50rem, /* name */
    99% 0.95rem, /* about-me */
    85% 0.95rem,  /* about-me */
    65% 0.95rem;  /* about-me */
  background-position:
    0 0,  /* caption */
    0 2.30rem, /* name */
    0 6.80rem, /* about-me */
    0 8.45rem, /* about-me */
    0 10.1rem; /* about-me */

  html.is-ready & {
    display: none;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`;
