import { Link } from 'components'
import { ContactSection, FooterMenu, StyledFooter } from './components'
import ContactInformation from './contacts'


export default function Footer() {
  return (
    <StyledFooter id='contacts' className='footer' data-nav-background="#3C394A" data-nav-color="#FFF">
      <div className="section-wrapper">

        <ContactSection className="contact-section">

          <div className="section-heading">
            <h5 className="caption">LIKE WHAT YOU SEE?</h5>
            <h2 className="title">Lets Work Together</h2>
          </div>

          <p className="section-description">
            I'm currently available for work, so if you'd like to discuss any projects or potential collaborations, you can reach me though any of the available contact information.
          </p>

          <ContactInformation/>

        </ContactSection>

        <FooterMenu className="block_delay" data-track-view>
          <ul className='menu-items'>
            <Link className='menu-link' to='/#skills' title='skills' />
            <div className='spacer' />
            <Link className='menu-link' to='/#projects' title='projects' />
            <div className='spacer' />
            <Link className='menu-link' to='/#about' title='about me' />
            <div className='spacer' />
            <Link className='menu-link' to='/#contacts' title='contact me' />
          </ul>
        </FooterMenu>

      </div>
    </StyledFooter>
  )
}
