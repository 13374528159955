import { graphql, StaticQuery } from "gatsby";
import { parseSanitySkills } from "utils/graphql/adapters";
import SkillsSection from "./component";

const Skills: React.FC = () => {
  return (
    <StaticQuery
      query={graphql`
        query allSanitySkills {
          skills: allSanitySkill(filter: {featured: {eq: true}}) {
            nodes {
              ...SanitySkillInfo
            }
          }
        }
      `}
      render={(data) => <SkillsSection data={parseSanitySkills(data.skills.nodes)} />}
    />
  )
}

export default Skills;
