import resume from 'assets/files/Anthony_Mwangi_Resume.pdf';
import heroImage from 'assets/images/hero-image.svg';
import { AppLogo, Experience, Grid } from 'components';
import { SectionContent, SkeletonLoader, StyledCoverImage, StyledMenu, StyledSection } from './components';

export default function Header() {
  return (
    <StyledSection id='header'>
      <div className="section-wrapper">

        <StyledMenu className="nav-menu">
          <AppLogo />
        </StyledMenu>

        <StyledCoverImage className="cover-pic">
          <div className="hero-image">
            <img src={heroImage} alt="hero" />
          </div>
        </StyledCoverImage>

        <SectionContent className="main-content">

          <SkeletonLoader />

          <div className="header_title">

            <span className="header_line">
              <h6 className="caption">
                Hello There, My Name is:
              </h6>
            </span>

            <span className="header_line">
              <h1 className="name">
                Anthony Mwangi
              </h1>
            </span>

            <span className="header_line">
              <p className="about-me">
                I am a <em>Front-End Software Engineer</em> with <Experience /> of experience in designing & building both web and mobile applications.
              </p>
            </span>

          </div>

          <div className="btn-wrapper">
            <a download href={resume} className={'btn download-btn'}>Download Resume</a>
          </div>

        </SectionContent>

      </div>
      <Grid />
    </StyledSection>
  )
}
