import styled from 'styled-components';

export const StyledSection = styled.section`
  z-index: 1;
  padding: 5rem 1rem;
  background-color: var(--blue);

  .section-wrapper {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 5rem;
  }

  .section-heading {
    .caption { color: var(--yellow) !important; }
    .title { color: var(--white) }
    .description {
      line-height: 1.9;
      margin-top: 2rem;
      color: var(--white) !important;
      b {
        font-weight: 600;
        letter-spacing: 0.02rem;
        color: var(--yellow) !important;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    padding: 5rem;

    .section-wrapper {
      gap: 1.5rem;
      max-width: 30rem !important;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2,auto);
    }
  }

  @media only screen and (max-width: 720px) {
    padding: 3.5rem 2rem;
  }

`;

export const SectionContent = styled.div`
  gap: 1rem;
  display: grid;
  margin-top: 2rem;
  height: min-content;
  grid-template-columns: repeat(2,1fr);

  @media only screen and (max-width: 720px) {
    gap: 1rem;
    margin-top: 0rem;
    grid-template-columns: repeat(2,1fr);
  }
`;

export const StyledSkill = styled.article`
  gap: 1rem;
  display: flex;
  align-items: center;
  height: auto;
  cursor: pointer;
  padding: 1.15rem 1.5rem;
  border-radius: var(--border-radius-1);
  flex-direction: row;
  justify-content: flex-start;
  background: var(--white);

  .icon-wrapper {
    width: 2rem;
    height: 2rem;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    transform: scale(1.4);
    .icon {
      margin: 0;
      width: 1.35rem;
      height: 1.35rem;
      object-fit: contain;
    }
  }

  .name {
    font-weight: 500;
    font-size: 0.7rem;
    letter-spacing: 0.02rem;
    text-transform: uppercase;
    color: darken(#6B6B6B,10);
  }

  @media only screen and (max-width: 720px) {
    gap: 1rem;
    padding: 1.25rem 1rem;
    .name {
      font-size: 0.8rem;
      letter-spacing: 0.01rem;
    }
  }
`;
