import { Head, Main, NavMenu } from "components";
import { PageProps } from "gatsby";
import React from "react";
import AboutMe from "views/about";
import Footer from "views/footer";
import Header from "views/header";
import Projects from "views/projects";
import Skills from "views/skills";

const HomePage: React.FC<PageProps> = () => {
  return (
    <>
      <Head title="Home" />
      <Main>
        <NavMenu />
        <Header />
        <Skills/>
        <Projects />
        <AboutMe />
        <Footer />
      </Main>
    </>
  )
}

export default HomePage;
