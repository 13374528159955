import styled from 'styled-components'

export const StyledSection = styled.section`
  padding: 5rem 1rem;
  background: var(--yellow);

  @media only screen and (max-width: 1024px) {
    padding: 5rem;
    .section-wrapper {
      max-width: 30rem !important;
    }
  }

  @media only screen and (max-width: 720px) {
    padding: 3.5rem 2rem;
  }
`

export const SectionContent = styled.div`
  gap: 2rem;
  display: grid;
  margin-top: 2.5rem;
  grid-template-columns: repeat(2,1fr);

  article {
    cursor: pointer;
    padding: 0 1.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1rem;
    background: transparent;
    transition: all .3s ease-in-out;
    border: 1px solid var(--black);
    border-radius: 0.2px;

    &.full-width {
      grid-column: 1 / -1;
    }

    .content-icon {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    .content-description {
      line-height: 1.8;
      font-size: 0.9rem;
    }

    &:hover {
      background: var(--white);
      border-color: var(--blue);
      .section-icon { color: var(--blue) }
    }
  }

  @media only screen and (max-width: 1024px) {
    gap: 2.5rem;
    grid-template-columns: repeat(1, 1fr);
  }

`
