import styled from 'styled-components';

export const StyledSection = styled.section`
  display: grid;
  position: relative;
  padding: 5rem 2.5rem;
  grid-template-columns: 0.16fr auto 0.16fr;

  .column-lines {
    background-color: var(--bg-color);
  }

  &::after, &::before {
    content: '';
    display: block;
    min-height: 1px;
  }

  .section-wrapper {
    max-width: 57rem !important;
  }

  .link-btn {
    color: var(--text-color) !important;
    background: transparent !important;
    border: 1px solid var(--border-color) !important;
    text-transform: uppercase;
    font-weight: 600 !important;
    font-size: 0.7rem !important;
    &::before { opacity: .1 !important }
    &:hover {
      color: var(--white) !important;
      background: var(--blue) !important;
      border-color: var(--blue) !important;
    }
  }

  .btn-wrapper {
    display: flex;
    margin-top: 5rem;
    align-items: center;
    justify-content: center;
    .btn {
      font-size: .9rem !important;
      padding: 1.15rem 1.5rem !important;
    }
  }

  @media only screen and (max-width: 1024px) {
    padding: 5rem;
    grid-template-columns: 1fr;
    background-color: var(--bg-color);

    &::after, &::before, .column-lines {
      display: none;
    }

    .section-wrapper {
      max-width: 30rem !important;
    }

    .btn-wrapper {
      margin-top: 3rem;
      margin-bottom: 1rem;
    }
  }

  @media only screen and (max-width: 720px) {
    padding: 2.5rem 2rem;
  }
`;

export const SectionHeading = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 1rem;

  @media only screen and (max-width: 1024px) {
    padding: 0;
    .link-btn { display: none !important }
    .title { margin-bottom: 1.75rem }
  }
`;

export const SectionContent = styled.div`
  gap: 3.5rem;
  display: grid;
  margin-top: 2rem;
  grid-template-columns: 1fr;
  padding-top: 2rem;

  @media only screen and (max-width: 1024px) {
    gap: 2.5rem;
    margin-top: 0rem;
  }

  @media only screen and (max-width: 720px) {
    gap: 1.5rem;
  }
`;
