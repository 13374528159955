import { Image } from 'components';
import React, { useMemo } from 'react';
import { Skill } from 'utils/types';
import { SectionContent, StyledSection, StyledSkill } from './components';

type SkillProps = {
  data: Skill[]
}

const SkillsSection: React.FC<SkillProps> = ({ data }) => {

  const skills = useMemo(() => {
    // filter out skills that aren't featured and order by ranking
    return data.filter(s => !!s.rank && !!s.featured).sort((a, b) => a.rank - b.rank)
  }, [data]);

  return (
    <StyledSection id='skills'>
      <div className="section-wrapper">

        <div className="section-heading">
          <h5 className="caption">ABOUT ME & MY WORK</h5>
          <h2 className="title">Skills & Experience</h2>
          <p className="description">
            I have a deep passion for my work, fueled by the desire to solve problems and create value. I am a self-motivated and self-driven individual who is always looking for new challenges and opportunities to grow and improve.
          </p>
          <p className="description">
            My experience and expertise as a software engineer is multi-faceted with a keen focus on front-end web development with <b>React</b> and <b>Typescript</b>. I've also worked on a variety of cross-platform mobile apps built with <b>React Native</b>.
          </p>
          <p className="description">
            Check out some of my work below:
          </p>
        </div>

        <SectionContent className="skill-grid">
          {
            skills.map((skill) =>
              <StyledSkill className='skill' key={`${skill.id}`}>
                <SkillIcon data={skill} />
                <div className="name">{skill.name}</div>
              </StyledSkill>
            )
          }
        </SectionContent>

      </div>
    </StyledSection>
  )
}


function SkillIcon({ data }: { data: Skill }) {
  const { name, image, color = '#464CAC' } = data;
  return (
    <div className='icon-wrapper' style={{ background: `${color}10` }}>
      <Image className='icon' src={image} alt={`${name} icon`} />
    </div>
  );
}

export default SkillsSection;
